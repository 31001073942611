import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartTool = _resolveComponent("ChartTool")!
  const _component_DataRender = _resolveComponent("DataRender")!

  return (_openBlock(), _createBlock(_component_DataRender, {
    class: "ChartRender",
    load: _ctx.handleLoad,
    ref: "dataElement"
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), {
        title: _ctx.title,
        category: _ctx.category,
        code: _ctx.code,
        modelValue: _ctx.values,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values) = $event))
      }, null, 8, ["title", "category", "code", "modelValue"])),
      _createVNode(_component_ChartTool, {
        onChange: _ctx.handleChange,
        search: _ctx.search
      }, null, 8, ["onChange", "search"])
    ]),
    _: 1
  }, 8, ["load"]))
}