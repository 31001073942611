import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "upload-btn" }
const _hoisted_2 = { class: "cover-img" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_finished = _resolveComponent("finished")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit")!
  const _component_refresh_left = _resolveComponent("refresh-left")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_delete = _resolveComponent("delete")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_Check = _resolveComponent("Check")!
  const _component_MaskContainer = _resolveComponent("MaskContainer")!
  const _component_CoverImage = _resolveComponent("CoverImage")!
  const _component_TablePagination = _resolveComponent("TablePagination")!
  const _component_DataRender = _resolveComponent("DataRender")!
  const _component_ModalContainer = _resolveComponent("ModalContainer")!

  return (_openBlock(), _createBlock(_component_ModalContainer, {
    class: "ModalCarousel",
    modalService: _ctx.modalService
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_ButtonSubmit, {
          type: "default",
          click: _ctx.handleClose
        }, {
          default: _withCtx(() => [
            _createTextVNode("取 消")
          ]),
          _: 1
        }, 8, ["click"]),
        _createVNode(_component_ButtonSubmit, { click: _ctx.handleSubmit }, {
          default: _withCtx(() => [
            _createTextVNode("确认选择")
          ]),
          _: 1
        }, 8, ["click"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DataRender, {
        class: "ModalFileManage",
        load: _ctx.handleLoad,
        onSuccess: _ctx.handleLoadSuccess
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "action-btn" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ButtonSubmit, {
                    type: "default",
                    size: "small",
                    click: _ctx.handleSelectAll
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_finished)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(" 选择全部")
                    ]),
                    _: 1
                  }, 8, ["click"]),
                  _createVNode(_component_ButtonSubmit, {
                    type: "default",
                    size: "small",
                    style: {"margin-left":"5px"},
                    click: _ctx.handleUnSelected,
                    disabled: _ctx.isDisableDeleteBtn
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_refresh_left)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(" 取消选中")
                    ]),
                    _: 1
                  }, 8, ["click", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 12,
                class: "action-container"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_el_upload, {
                      ref: "uploadElement",
                      "list-type": "text",
                      data: _ctx.uploadParams,
                      headers: _ctx.headers,
                      multiple: "",
                      "on-success": _ctx.onUploaded,
                      "on-error": _ctx.onUploaded,
                      action: _ctx.action
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ButtonSubmit, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_plus)
                              ]),
                              _: 1
                            }),
                            _createTextVNode(" 添加图片")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["data", "headers", "on-success", "on-error", "action"])
                  ]),
                  _createVNode(_component_ButtonDelete, {
                    click: _ctx.handleDestroyMore,
                    clickAfter: _ctx.fetchIndex,
                    disabled: _ctx.isDisableDeleteBtn
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_delete)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(" 删除选中项 ")
                    ]),
                    _: 1
                  }, 8, ["click", "clickAfter", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, {
            class: "image-list",
            gutter: 8
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (v, index) => {
                return (_openBlock(), _createBlock(_component_el_col, {
                  span: 4,
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      (v.active)
                        ? (_openBlock(), _createBlock(_component_MaskContainer, {
                            key: 0,
                            onClick: _withModifiers(($event: any) => (_ctx.handleSelectImage(v)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_icon, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Check)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_openBlock(), _createBlock(_component_CoverImage, {
                        src: v.full_url,
                        key: v.full_url,
                        zip: "360",
                        onClick: _withModifiers(($event: any) => (_ctx.handleSelectImage(v)), ["stop"])
                      }, null, 8, ["src", "onClick"]))
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_TablePagination, {
            onChangePage: _ctx.handleChangePage,
            pagination: _ctx.pagination
          }, null, 8, ["onChangePage", "pagination"])
        ]),
        _: 1
      }, 8, ["load", "onSuccess"])
    ]),
    _: 1
  }, 8, ["modalService"]))
}