import { createApp, App, DefineComponent } from 'vue'
import { Router } from 'vue-router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import RouterViewContainer from 'pk/components/RouterViewContainer.vue'
import DataRender from 'pk/components/DataRender.vue'
import PageContainer from 'pk/components/PageContainer.vue'
import ButtonSubmit from 'pk/components/Button/ButtonSubmit.vue'
import ButtonDelete from 'pk/components/Button/ButtonDelete.vue'
import TableRender from 'pk/components/Table/TableRender.vue'
import TableText from 'pk/components/Table/TableText.vue'
import TableOptions from 'pk/components/Table/TableOptions.vue'
import TableImages from 'pk/components/Table/TableImages.vue'
import TableImage from 'pk/components/Table/TableImage.vue'
import TableCascader from 'pk/components/Table/TableCascader.vue'
import TableButtons from 'pk/components/Table/components/TableButtons.vue'
import TableAction from 'pk/components/Table/TableAction.vue'
import CoverImage from 'pk/components/CoverImage.vue'
import FormRender from 'pk/components/Form/FormRender.vue'
import FormInput from 'pk/components/Form/FormInput.vue'
import FormSelect from 'pk/components/Form/FormSelect.vue'
import FormDate from 'pk/components/Form/FormDate.vue'
import FormCounter from 'pk/components/Form/FormCounter.vue'
import FormCheckbox from 'pk/components/Form/FormCheckbox.vue'
import FormRadio from 'pk/components/Form/FormRadio.vue'
import FormSwitch from 'pk/components/Form/FormSwitch.vue'
import FormText from 'pk/components/Form/FormText.vue'
import FormTextarea from 'pk/components/Form/FormTextarea.vue'
import FormCascader from 'pk/components/Form/FormCascader.vue'
import FormRangeDate from 'pk/components/Form/FormRangeDate.vue'
import FormTime from 'pk/components/Form/FormTime.vue'
import FormMultipleCascader from 'pk/components/Form/FormMultipleCascader.vue'
import FormImage from 'pk/components/Form/FormImage.vue'
import FormImages from 'pk/components/Form/FormImages.vue'
import FormTree from 'pk/components/Form/FormTree.vue'
import FormGroupPopup from 'pk/components/Form/FormGroupPopup.vue'
import FormGiveCoupon from 'pk/components/Form/FormGiveCoupon.vue'
import FormSalary from 'pk/components/Form/FormSalary.vue'
import ChartRender from 'pk/components/Charts/ChartRender.vue'
import ChartTool from 'pk/components/Charts/ChartTool.vue'
import ChartLine from 'pk/components/Charts/ChartLine.vue'

import AuthService from './AuthService'
export default class AppService {
  app!: App | any
  element!: Element
  props!: { [key: string]: any }
  component!: any
  components = [] as { name: string; component: DefineComponent<{}, {}, any> }[]
  baseComponents = [
    { name: 'RouterViewContainer', component: RouterViewContainer },
    { name: 'DataRender', component: DataRender },
    { name: 'PageContainer', component: PageContainer },
    { name: 'CoverImage', component: CoverImage },
    { name: 'ButtonSubmit', component: ButtonSubmit },
    { name: 'ButtonDelete', component: ButtonDelete },
    { name: 'TableRender', component: TableRender },
    { name: 'TableText', component: TableText },
    { name: 'TableOptions', component: TableOptions },
    { name: 'TableImages', component: TableImages },
    { name: 'TableImage', component: TableImage },
    { name: 'TableCascader', component: TableCascader },
    { name: 'TableButtons', component: TableButtons },
    { name: 'TableAction', component: TableAction },
    { name: 'FormRender', component: FormRender },
    { name: 'FormInput', component: FormInput },
    { name: 'FormSelect', component: FormSelect },
    { name: 'FormDate', component: FormDate },
    { name: 'FormCounter', component: FormCounter },
    { name: 'FormCheckbox', component: FormCheckbox },
    { name: 'FormRadio', component: FormRadio },
    { name: 'FormSwitch', component: FormSwitch },
    { name: 'FormText', component: FormText },
    { name: 'FormTextarea', component: FormTextarea },
    { name: 'FormCascader', component: FormCascader },
    { name: 'FormRangeDate', component: FormRangeDate },
    { name: 'FormTime', component: FormTime },
    { name: 'FormMultipleCascader', component: FormMultipleCascader },
    { name: 'FormImage', component: FormImage },
    { name: 'FormImages', component: FormImages },
    { name: 'FormTree', component: FormTree },
    { name: 'FormGroupPopup', component: FormGroupPopup },
    { name: 'FormGiveCoupon', component: FormGiveCoupon },
    { name: 'FormSalary', component: FormSalary },
    { name: 'ChartRender', component: ChartRender },
    { name: 'ChartTool', component: ChartTool },
    { name: 'ChartLine', component: ChartLine }
  ]

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor (component: any, props?: any) {
    this.component = component
    this.props = props
  }

  public createApp (router: Router): this {
    this.makeApp().use(router).mount('#app')
    return this
  }

  public createComponent (): this {
    this.element = document.body.appendChild(document.createElement('div'))
    this.makeApp().mount(this.element)
    return this
  }

  private makeApp () {
    this.app = createApp(this.component, this.props)
      .use(ElementPlus)
    this.baseComponents.forEach((res) => {
      this.app.component(res.name, res.component)
    })
    this.components.forEach((res) => {
      this.app.component(res.name, res.component)
    })
    this.app.directive('permission', (el: HTMLFormElement, params: { [key: string]: any }) => {
      const result = AuthService.hasPermission(params.value)
      if (!result) {
        el.style.display = 'none'
      }
    })
    return this.app
  }

  public destroy (): void {
    this.app.unmount()
    document.body.removeChild(this.element)
  }
}
