
import { defineComponent } from 'vue'
import ModalAbstract from '../Modal/ModalAbstract'
import FormFactory, { InfoService } from './FormFactory'
import PageFormService from './PageFormService'

export default defineComponent({
  props: {
    form: {
      type: Object,
      required: true
    },
    submit: Function,
    afterSubmit: {
      type: Function,
      default: (res: any) => Promise.resolve(res)
    },
    load: Function,
    afterLoad: {
      type: Function,
      default: () => Promise.resolve()
    },
    beforeLoad: {
      type: Function,
      default: () => Promise.resolve()
    },
    infoService: {
      type: Object as () => InfoService
    },
    disableSubmitAndBack: {
      type: Boolean,
      default: false
    },
    disableFooter: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String,
      default: '115px'
    },
    modalService: {
      type: Object as () => ModalAbstract
    },
    width: {
      type: String,
      default: '50%'
    }
  },
  provide () {
    return {
      formId: this.formId
    }
  },
  data () {
    return {
      formId: new Date().getTime() + Math.round(Math.random() * 1000),
      isShowSubmitValidateError: false,
      inDialog: false,
      formService: new FormFactory({
        form: this.form,
        onSubmit: this.submit,
        onAfterSubmit: this.afterSubmit,
        onLoad: this.load,
        onAfterLoad: this.afterLoad,
        onBeforeLoad: this.beforeLoad,
        infoService: this.infoService,
        modalService: this.modalService,
        validateField: this.validateField
      }),
      FormElement: null as any
    }
  },
  computed: {
    drElement (): any {
      return this.$refs.drElement
    }
  },
  created () {
    PageFormService.add({
      id: this.formId,
      service: this.formService
    })
  },
  methods: {
    handleLoad () {
      return this.formService.load()
    },
    handleSuccess () {
      this.FormElement = this.$refs.FormElement
      this.$emit('success')
    },
    handleSubmitAndBack () {
      return this.validate()
        .then(() => {
          if (this.disableSubmitAndBack) {
            return this.formService.submit()
          } else {
            return this.formService.submitAndBack()
          }
        })
    },
    handleReset () {
      this.FormElement.resetFields()
      this.isShowSubmitValidateError = false
      this.$emit('formReset')
    },
    validate () {
      return this.FormElement.validate()
        .then(() => {
          this.isShowSubmitValidateError = false
        })
        .catch((err: any) => {
          this.isShowSubmitValidateError = true
          throw err
        })
    },
    validateField (props: string | string[]) {
      this.FormElement.validateField(props)
    },
    reload () {
      return this.drElement.reload()
    },
    handleSubmit () {
      return this.validate()
        .then(() => this.formService.submit())
    }
  }
})
