import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import hr from './admin/hr'
import sd from './admin/sd'
import member from './admin/member'
import admin from './admin/admin'
import operation from './admin/operation'
import config from './admin/config'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/biz/admin/views/login.vue')
  },
  {
    path: '/',
    component: () => import('pk/views/main.vue'),
    children: [
      ...hr,
      ...sd,
      ...member,
      ...admin,
      ...operation,
      ...config
    ],
    redirect: '/hr/job/list'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
