import areaOptions from 'pk/assets/json/area'

export const appEnv = location.host.includes('admin-test.') ? 'test' : (location.host.includes('admin.') ? 'prod' : 'dev')
export const appApi = resolveUrl(location.hostname)

export function resolveUrl (host: string, subDomain = 'api') {
  const topDomain = host.split('.').slice(-2).join('.')
  const env = appEnv === 'prod' ? '' : appEnv
  subDomain = env ? `${subDomain}-${env}` : subDomain
  return `${location.protocol}//${subDomain}.${topDomain}`
}

function recursiveGetAncestors (id: number, list: any[], paths: any[], field: string) {
  const item: any = list.find((res: any) => res[field] === id)
  if (item) {
    paths.unshift(item)
    if (item.parent_id) {
      recursiveGetAncestors(item.parent_id, list, paths, field)
    }
  }
  return paths
}

function getCodeDeep (code: string | number) {
  code = code.toString()
  const len = code.length / 3
  const groupValue = len === 2 ? '00' : '000'
  const arr = []
  for (let i = 0; i < 3; i++) {
    arr.push(code.substr(i * len, len))
  }
  return arr.filter((res: string) => res !== groupValue).length
}

/**
 * 在一个树形结构中，给出一个id，递归获得当前对象与先祖的结果列表
 * @param currentId
 * @param list
 * @param field 索引的字段
 * @return [object(先祖), object(父级), object(当前)]
 */
export function getAncestorsAndSelf (currentId: number, list: any[], field = 'id'): any[] {
  if (currentId) {
    return recursiveGetAncestors(currentId, list, [], field)
  } else {
    return []
  }
}

/**
 * @param code 101010
 * @return [100000, 101000, 101010]
 */
export function getCodeParents (code: string | number): number[] {
  if (!code) return []
  code = code.toString()
  const len = code.length / 3
  const deep = getCodeDeep(code)
  const codes = []
  for (let i = 1; i < deep; i++) {
    codes.push(Number(code.substr(0, i * len).padEnd(len * 3, '0')))
  }
  codes.push(Number(code))
  return codes
}

/**
 * @param codes [100000, 101000, 101010]
 * @param datas tree结构
 * @return [item, item, item]
 */
export function codesToPaths (codes: number[], datas: any[]) {
  let list: any[] = JSON.parse(JSON.stringify(datas))
  return codes.map((code: number) => {
    const item = list.find((res) => res.id === code) as any
    if (item) {
      list = item.children as any[]
    }
    return item
  }).filter((res) => res)
}

function getDate (value?: string | Date | number) {
  let date!: Date
  if (value) {
    if (typeof value === 'string') {
      value = value.replace(/-/g, '/')
      date = new Date(value)
    } else if (typeof value === 'number') {
      date = new Date(value)
    } else {
      date = value
    }
  } else {
    date = new Date()
  }
  return date
}

/**
 * 格式化日期
 * @param value
 * @param fmt Y-m-d/Y-m-d H:i/Y-m
 */
export function formatDate (value?: Date, fmt = 'Y-m-d'): string {
  const date = getDate(value)

  const year = date.getFullYear()
  let month: string = (date.getMonth() + 1) + ''
  let day: string = date.getDate() + ''
  let hours: string = date.getHours() + ''
  let minutes: string = date.getMinutes() + ''
  let seconds: string = date.getSeconds() + ''
  if (month.length === 1) {
    month = '0' + month
  }
  if (day.length === 1) {
    day = '0' + day
  }
  if (hours.length === 1) {
    hours = '0' + hours
  }
  if (minutes.length === 1) {
    minutes = '0' + minutes
  }
  if (seconds.length === 1) {
    seconds = '0' + seconds
  }
  if (fmt === 'Y-m-d H:i') {
    return `${year}-${month}-${day} ${hours}:${minutes}`
  } else if (fmt === 'Y-m') {
    return `${year}-${month}`
  } else if (fmt === 'Y-m-d H:i:s') {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  } else {
    return `${year}-${month}-${day}`
  }
}

export function getDiffTime (maxTime: any, minTime: any, ms_number = 2) {
  const maxDateTime = getDate(maxTime).getTime()
  const minDateTime = getDate(minTime).getTime()
  const diffTime = (maxDateTime - minDateTime) / 1000
  const times = diffTime.toString().split('.')
  times[1] = (times[1] || '0').padEnd(ms_number, '0')
  const s = Number(times[0]) % 60
  const m = Math.floor(Number(times[0]) / 60)
  let h = 0
  if (m >= 60) {
    h = Math.floor(m / 60)
  }
  const i = m % 60
  const hStr = h.toString().padStart(2, '0')
  const iStr = i.toString().padStart(2, '0')
  const sStr = s.toString().padStart(2, '0')
  if (h) {
    return `${hStr}:${iStr}:${sStr}.${times[1]}`
  } else {
    return `${iStr}:${sStr}.${times[1]}`
  }
}

export function getDeepValue (field: string, row: any) {
  // 例：field = price||set_meal.price
  return field.split('||') // ['price', 'set_meal.price']
    .filter((key: string) => key) // ['price', 'set_meal.price']
    .map((field: string) => { // 返回 ['', '11.00']
      // set_meal.price
      return field.split('.') // ['set_meal', 'price']
        .filter((key: string) => key)
        .reduce((acc, key: string) => {
          // 循环第一次：row['set_meal']，第二次：row['set_meal']['price']
          if (acc && acc[key]) {
            return acc[key]
          } else {
            return null
          }
        }, row)
    })
    .find((value) => value) // 找到有效的值返回
}

// 大驼峰转下划线
export function humpToLine (name: string) {
  let str = name.replace(/([A-Z])/g, '_$1').toLowerCase()
  const dot = str.substr(0, 1)
  if (dot === '_') {
    str = str.substr(1)
  }
  return str
}

export function arrayChunk (arr: any[], num: number) {
  const list = []
  for (let i = 0, max = Math.ceil(arr.length / num); i < max; i++) {
    list.push(arr.splice(0, num))
  }
  return list
}

export function resolveRacingNumber (num: string | number, n: number) {
  num = num || 0
  let len = num.toString().length
  while (len < n) {
    num = '0' + num
    len++
  }
  return num
}

export function resolveImageUrl (path: string, zip: string) {
  if (zip && ((path || '').includes('img.chinasaiche') || (path || '').includes('img-test.chinasaiche'))) {
    if (path.includes('!')) {
      const paths = path.match(/!(.*)/)
      if (paths) {
        return path.replace(paths[0], `!${zip}`)
      }
    } else {
      return `${path}!${zip}`
    }
  } else {
    return path
  }
}

export function getCityName (code: string, columnsNum = 3, format = '/'): string {
  if (!code) return '--'
  const arr: string[] = []
  code = code.toString()
  const provinceCode = Number(code.slice(0, 2).padEnd(6, '0'))
  const cityCode = Number(code.slice(0, 4).padEnd(6, '0'))
  const provinceItem = areaOptions.find((item) => item.id === provinceCode)
  if (!provinceItem) {
    return '--'
  }
  arr.push(provinceItem.name)
  const cityItem = provinceItem.children.find((item) => item.id === cityCode)
  if (!cityItem) {
    return '--'
  }
  arr.push(cityItem.name)
  if (columnsNum === 3) {
    const countyItem = cityItem.children.find((item) => item.id === Number(code))
    if (!countyItem) {
      return '--'
    }
    arr.push(countyItem.name)
  }
  return arr.join(format)
}

export function getLastStr (str: string, exp: string): string {
  const arr = str.split(exp)
  return arr.pop() as string
}
