import RouterService from '@/pk/src/service/RouterService'
import { Medal, Postcard, User } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

export default [
  {
    path: '/member',
    component: () => import('pk/views/sider.vue'),
    meta: {
      title: '会员管理'
    },
    children: [
      {
        path: '/member/user',
        component: () => import('@/biz/admin/views/member/user/index.vue'),
        meta: {
          title: '会员列表',
          icon: markRaw(User)
        },
        children: [
          {
            path: '/member/user/form',
            component: () => import('@/biz/admin/views/member/user/form.vue'),
            meta: {
              title: '编辑会员',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/member/role',
        component: () => import('@/biz/admin/views/member/role/index.vue'),
        meta: {
          title: '会员角色',
          icon: markRaw(Medal)
        },
        children: [
          {
            path: '/member/role/form',
            component: () => import('@/biz/admin/views/member/role/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑会员角色' : '添加会员角色'
              },
              isForm: true
            }
          },
          {
            path: '/member/role/permissions',
            component: () => import('@/biz/admin/views/member/role/permissions.vue'),
            meta: {
              title: '权限管理',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/member/personal-auth',
        component: () => import('@/biz/admin/views/member/personal-auth/index.vue'),
        meta: {
          title: '个人认证',
          icon: markRaw(Postcard)
        },
        children: [
          {
            path: '/member/personal-auth/form',
            component: () => import('@/biz/admin/views/member/personal-auth/form.vue'),
            meta: {
              title: '认证详情',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/member/enterprise-auth',
        component: () => import('@/biz/admin/views/member/enterprise-auth/index.vue'),
        meta: {
          title: '企业认证',
          icon: markRaw(Postcard)
        },
        children: [
          {
            path: '/member/enterprise-auth/form',
            component: () => import('@/biz/admin/views/member/enterprise-auth/form.vue'),
            meta: {
              title: '认证详情',
              isForm: true
            }
          }
        ]
      }
    ]
  }
]
