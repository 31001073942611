
import { ISearchFields } from 'pk/interface/common'
import RouterService from 'pk/service/RouterService'
import { defineComponent } from 'vue'
import TableFactory from './TableFactory'
import PageTableService from './PageTableService'
import TablePagination from './TablePagination.vue'
import SearchTool from '../SearchTool/SearchTool.vue'

export default defineComponent({
  components: {
    TablePagination,
    SearchTool
  },
  props: {
    load: Function,
    beforeLoad: {
      type: Function,
      default: () => Promise.resolve()
    },
    searchFields: {
      type: Array as () => ISearchFields[]
    },
    infoService: Object,
    spanMethod: Function,
    maxHeight: Number,
    align: String,
    print: Boolean,
    defaultExpandAll: Boolean,
    refresh: Function,
    disableCreate: {
      type: Boolean,
      default: false
    }
  },
  provide () {
    return {
      tableId: this.tableId
    }
  },
  data () {
    return {
      tableId: new Date().getTime() + Math.round(Math.random() * 1000),
      innerMaxHeight: 0,
      RouterService,
      tableService: new TableFactory({ infoService: this.infoService, onLoad: this.load, onBeforeLoad: this.beforeLoad, align: this.align })
    }
  },
  created () {
    PageTableService.add({
      id: this.tableId,
      service: this.tableService
    })
  },
  computed: {
    drElement (): any {
      return this.$refs.drElement
    },
    tableElement (): any {
      return this.$refs.table
    }
  },
  methods: {
    handleLoad () {
      if (this.drElement) {
        this.innerMaxHeight = this.maxHeight ? this.maxHeight : (this.print ? 3000 : document.documentElement.clientHeight - (this.drElement.$el.offsetTop + 180))
      }
      return this.tableService.load()
    },
    handleLoadSuccess () {
      this.$emit('loadSuccess')
    },
    reload (params: any) {
      return this.tableService.reload(params)
    },
    handleSelectionChange (values: any) {
      this.$emit('selection-change', values)
    },
    toggleRowSelection (row: any, bool?: boolean) {
      const item = this.tableService.list.find((res) => res.id === row.id)
      if (item) {
        this.tableElement.toggleRowSelection(item, bool)
      }
    },
    clearSelection () {
      this.tableElement.clearSelection()
    }
  }
})
