import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(_component_el_table_column, {
    prop: _ctx.prop,
    label: _ctx.label,
    width: _ctx.width,
    "min-width": _ctx.minWidth,
    align: _ctx.tableService.align || _ctx.align,
    "class-name": _ctx.innerClassName
  }, {
    default: _withCtx((scope) => [
      (_ctx.prop === 'title')
        ? (_openBlock(), _createBlock(_component_el_popover, {
            key: 0,
            placement: "right",
            trigger: "click"
          }, {
            reference: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "small",
                link: "",
                type: "primary",
                class: "TableText-title-button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getValue(scope.row) || '--'), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            default: _withCtx(() => [
              _createElementVNode("p", null, "id: " + _toDisplayString(scope.row.id), 1),
              _createElementVNode("p", null, "标题: " + _toDisplayString(scope.row.title), 1)
            ]),
            _: 2
          }, 1024))
        : (_ctx.prop.indexOf('.nickname') > -1)
          ? (_openBlock(), _createBlock(_component_el_popover, {
              key: 1,
              placement: "right",
              trigger: "click"
            }, {
              reference: _withCtx(() => [
                _createVNode(_component_el_button, {
                  size: "small",
                  link: "",
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getValue(scope.row) || '--'), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              default: _withCtx(() => [
                _createElementVNode("p", null, "id: " + _toDisplayString(_ctx.getValue(scope.row, _ctx.prop.replace('.nickname', '.id')) || '--'), 1),
                _createElementVNode("p", null, "昵称: " + _toDisplayString(_ctx.getValue(scope.row) || '--'), 1)
              ]),
              _: 2
            }, 1024))
          : (_ctx.isHtml === true)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                innerHTML: _ctx.getValue(scope.row)
              }, null, 8, _hoisted_1))
            : (_openBlock(), _createElementBlock("span", {
                key: 3,
                style: _normalizeStyle({ color: _ctx.getColor() })
              }, _toDisplayString(_ctx.getValue(scope.row)), 5))
    ]),
    _: 1
  }, 8, ["prop", "label", "width", "min-width", "align", "class-name"]))
}